/* Styling for a tooltip arrow. */

.tooltip-arrow {
  position: absolute;
  width: 6px;
  height: 6px;
  z-index: 1;
  border-color: inherit;
}

.tooltip-arrow::before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  @apply bg-zinc-800;
  width: 6px;
  height: 6px;
  box-sizing: content-box;
}

.tooltip-arrow[data-placement^='top'] {
  bottom: -2px;
}

.tooltip-arrow[data-placement^='top']::before {
  @apply border-b border-r;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='bottom'] {
  top: -3px;
}

.tooltip-arrow[data-placement^='bottom']::before {
  @apply border-t border-l;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='left'] {
  right: -2px;
}

.tooltip-arrow[data-placement^='left']::before {
  @apply border-t border-r;
  border-color: inherit;
}

.tooltip-arrow[data-placement^='right'] {
  left: -3px;
}

.tooltip-arrow[data-placement^='right']::before {
  @apply border-b border-l;
  border-color: inherit;
}

/* Styling for fat tooltip arrow. */

.tooltip-arrow.tooltip-big {
  width: 18px;
  height: 18px;
}

.tooltip-arrow.tooltip-big::before {
  width: 18px;
  height: 18px;
}

.tooltip-arrow.tooltip-big[data-placement^='top'] {
  bottom: -10px;
}

.tooltip-arrow.tooltip-big[data-placement^='bottom'] {
  top: -10px;
}

.tooltip-arrow.tooltip-big[data-placement^='left'] {
  right: -6px;
}

.tooltip-arrow.tooltip-big[data-placement^='right'] {
  left: -6px;
}
